// TicketDetailページ
import { useEffect,useState,useRef } from 'react';
import { 
    Card, CardActionArea, CardContent, CardMedia, Typography, Button
} from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";
import { Link,useLocation } from "react-router-dom";
import axios from "axios"
import { useCookies } from 'react-cookie';
import Authorize from '../utils/Authorize';

const useStyles = makeStyles({
  count: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "linear-gradient(to bottom, #ffffff, #e6e6e6)",
    height: "300px",
    width: "400px",
    borderRadius: "15px",
    boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)",
    marginBottom: "20px",
  },
  typography: {
    fontFamily: "Arial",
    fontSize: "120px !important",
    fontWeight: "bold",
    color: "#ff6767",
  },
  button: {
    fontSize: "40px !important",
    marginTop: "50px !important",
    backgroundColor: "#ff6666 !important",
    color: "#ffffff !important",
    "&:hover": {
      backgroundColor: "#ff4d4d !important",
    },
  },
});

//メイン関数
const TicketDetail = () => {
  const [input, setInput] = useState<string>("");
  const [leftcoupon, setLeftCoupon] = useState<number>(999);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>(undefined);
  const classes = useStyles();

  const handleKeyDown = (event: KeyboardEvent) => {
    const key = event.key;
    clearTimeout(timeoutId);
    //URL safeをハードコーディングでやっつけている
    if(key.toString() === '=') {
     setInput(prevInput => prevInput + "=");
     // setInput(prevInput => prevInput + "_");
    } else if(key.toString() === '^') {
     // setInput(prevInput => prevInput + "=");
    } else if(key !== 'Shift') {
     setInput(prevInput => prevInput + key);
    }
    setTimeoutId(setTimeout(() => {
      setLeftCoupon(999);
      //console.log(input);
      //入力完了した文字列に対して行う処理の記載
      const url = "https://9w8ea9vjoi.execute-api.ap-northeast-1.amazonaws.com/dev?"
                + "qr_string=" + input;
      console.log(url);
      getApiData(url)
      .then(data => {
        console.log(data);
        let res = JSON.parse(JSON.stringify(data));
          setLeftCoupon(data.leftCoupon);
        })
      .catch(error => console.error(error));
      
      setInput("");
      
      const timer = setTimeout(() => {
        setLeftCoupon(999);
      }, 8000);
    }, 300));
  };

  const getApiData = async(url: string) => {
    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      if (timeoutId !== undefined) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);
  return (
    <div>
      <h1>QRリーダーにチケットをかざすと、現在の有効杯数が確認できます</h1>
      <div className={classes.count}>
      <Card className={classes.card}>
        <CardContent>
          <Typography className={classes.typography}>
            {leftcoupon === 999 ? '' : `${leftcoupon}杯`}
          </Typography>
        </CardContent>
      </Card>
      </div>
    </div>
  );
/*
  return (
    <div>
      <h1>QRリーダーにチケットをかざすと、現在の有効杯数が確認できます</h1>
      <div className={classes.count}>
      <Card className={classes.card}>
        <CardContent>
          <Typography className={classes.typography}>
            {leftcoupon === 0 ? '' : `${leftcoupon}杯`}
          </Typography>
        </CardContent>
      </Card>
      </div>
      <Button className={classes.button}>
        back
      </Button>
    </div>
  );
*/
};
export default TicketDetail;

