import React from 'react';
import './App.css';
import {Routes, Route} from 'react-router';
import Top from './pages/Top'
import TicketDetail from './pages/TicketDetail';

const App = () => {
  return (
    <div className="App">
        <Routes>
          <Route path="/" element={<TicketDetail />}/>
          <Route path="/TicketDetail" element={<TicketDetail/>} />
        </Routes>
    </div>
  );
}

export default App;
